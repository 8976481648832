import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getGiftLogsByCardNum(giCardNum){
        return getRequest(`/send/gifs/getGiftLogsByCardNum?giCardNum=${giCardNum}`)
    },
    getListByGiCardNum(giCardNum){
        return getRequest(`/send/gifs/getListByGiCardNum?giCardNum=${giCardNum}`)
    },
    getWriteOff(cardNum,offcCouTypes){
        return postRequest(`/send/gifs/getWriteOff?cardNum=${cardNum}&offcCouTypes=${offcCouTypes}`)
    },
    getTimeCardByCardNum(giCardNum){
        return getRequest(`/send/gifs/getTimeCardByCardNum?giCardNum=${giCardNum}`)
    },
    getListByTimeCardNum(sriCardNum){
        return getRequest(`/send/gifs/getListByTimeCardNum?sriCardNum=${sriCardNum}`)
    }
}